<template>
    <div>
        <div class="container mx-auto h-screen flex items-center justify-center">
            <div class="text-center px-4 w-full">
                <div class="h-24">
                    <img
                        class="logo"
                        src="~@/assets/images/linquito-logo.svg?data"
                        alt="Linquito">

                    <h2 class="hidden sm:block">
                        <text-fade-slider
                            :texts="texts">
                        </text-fade-slider>
                    </h2>
                    <h3 class="block sm:hidden">
                        <text-fade-slider
                            :texts="texts">
                        </text-fade-slider>
                    </h3>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {shuffle} from '@/helpers/ArrayHelpers';
    import TextFadeSlider from '@/components/structure/TextFadeSlider';

    export default {
        name      : 'ComingSoon',
        data      : () => {
            return {
                texts: shuffle([
                    'Coming soon',
                    'Bientôt disponible',
                    'Muy pronto',
                    'Em breve',
                    'Prossimamente',
                    'Kommt bald',
                    '快来了',
                    '网页未找到',
                    '近日公開',
                ]),
            };
        },
        components: {
            TextFadeSlider,
        },
    };
</script>

<style scoped>
    .logo {
        @apply mx-auto;
        width: 13rem;
    }
</style>
